@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --sidebar-bg: #f8fafc;
  --sidebar-hover: #f1f5f9;
}

body {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f1f5f9;
}
